<template>
  <div class="simple-row data-refresh-area" v-if="!shouldHide">
    <div style="margin-right: 6px;">{{ toHumanFriendlyMessage(lastRefreshed, clockTime) }}</div>
    <v-btn
        class="refresh-button"
        @click="refreshClicked"
        rounded color="primary"
        outlined
    >
      <v-icon small left>mdi-reload</v-icon>
      Refresh
    </v-btn>
  </div>
</template>

<script>

const CLOCK_UPDATE_INTERVAL = process.env.VUE_APP_CLOCK_UPDATE_INTERVAL;
const AUTO_REFRESH_MIN_THRESHOLD = process.env.VUE_APP_AUTO_REFRESH_MIN_THRESHOLD;

export default {
  name: 'DataRefresher',
  props: ['shouldHide'],
  data() {
    return {
      clockTime: null,
      lastRefreshed: null,
      lastAttempted: null,
      wasLastRefreshSuccessful: false
    };
  },
  created() {
    this.updateClock();
  },
  methods: {
    updateClock() {
      this.clockTime = Date.now();

      if (this.lastRefreshed) {
        let diff = this.clockTime - Math.max(this.lastRefreshed, this.lastAttempted);
        if (diff > AUTO_REFRESH_MIN_THRESHOLD) {
          this.refreshClicked();
        }
      }

      setTimeout(() => {
        this.updateClock();
      }, CLOCK_UPDATE_INTERVAL);
    },
    toHumanFriendlyMessage(lastRefreshed, clockTime) {
      if (lastRefreshed === null || clockTime === null) return "Updating...";

      let diff = Math.ceil((clockTime - lastRefreshed) / 1000 / 60);

      if (this.wasLastRefreshSuccessful) {
        if (diff <= 2) return "Updated just now";
        return `Last updated ${diff} minutes ago.`;
      }

      return `Refresh failed. Last updated ${diff} minutes ago.`;
    },
    refreshClicked() {
      this.lastAttempted = Date.now();
      this.$emit('refresh-request');
    },
    notifyDataRefresh(wasSuccessful) {
      this.wasLastRefreshSuccessful = wasSuccessful;
      this.lastRefreshed = Date.now();
    }
  }
};

</script>

<style scoped>
.data-refresh-area {
  margin-top: 8px;
  margin-bottom: 16px;
}
</style>
