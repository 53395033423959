<template>
  <div>

    <div class="buttoned-month-selector-container">
      <div class="buttoned-month-selector-label">Select Month</div>
      <div class="simple-row buttoned-month-selector-inner-container">
        <div v-for="monthName in monthNameList" :key="monthName"
             class="buttoned-month-selector"
             v-bind:class="{ 'buttoned-month-selector-active': monthName===selectedMonth }"
             @click="monthNameClicked(monthName)"
        >
          {{ monthName }}
        </div>
      </div>
    </div>

    <div class="new-summary-container simple-row--break-on-tablet">
      <div class="new-summary-group simple-row">
        <template v-if="isSummaryReady(transactionSummary)">
          <div class="summary-item secondary-summary">
            <span class="summary-title">Account Balance</span>
            <div style="display: flex; flex-direction: row;  margin-top: 5px;">
              <span class="currency-text-tr" style="align-self: flex-start">৳</span>
              <span class="summary-amount-tr" :class="{'smaller':String(this.transactionSummary.accountBalance).length > 7}">{{ toCommas(this.transactionSummary.accountBalance) }}</span>
            </div>
          </div>
          <div class="summary-item secondary-summary">
            <span class="summary-title">Payment Received</span>
            <div style="display: flex; flex-direction: row;  margin-top: 5px;">
              <span class="currency-text-tr" style="align-self: flex-start">৳</span>
              <span class="summary-amount-tr">{{ toCommas(this.transactionSummary.paymentReceived) }}</span>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="summary-item secondary-summary" style="justify-content: center;">
            <span class="summary-amount-tr">Account Balance</span>
          </div>
          <div class="summary-item secondary-summary" style="justify-content: center;">
            <span class="summary-amount-tr">Payment Received</span>
          </div>
        </template>
      </div>
      <div class="new-summary-group simple-row">
        <template v-if="isSummaryReady(transactionSummary)">
          <div class="summary-item secondary-summary">
            <span class="summary-title">Cash Out</span>
            <div style="display: flex; flex-direction: row;  margin-top: 5px;">
              <span class="currency-text-tr" style="align-self: flex-start">৳</span>
              <span class="summary-amount-tr">{{ toCommas(this.transactionSummary.cashOut) }}</span>
            </div>
          </div>
          <div class="summary-item secondary-summary">
            <span class="summary-title">Total Transactions</span>
            <div style="display: flex; flex-direction: row;  margin-top: 5px;">
              <span class="currency-text-tr"
                    style="align-self: flex-start">{{ toCommas(this.transactionSummary.transactionCount) }}</span>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="summary-item secondary-summary" style="justify-content: center;">
            <span class="summary-amount-tr">Cash out</span>
          </div>
          <div class="summary-item secondary-summary" style="justify-content: center;">
            <span class="summary-amount-tr">Total Transactions</span>
          </div>
        </template>
      </div>
      <div class="new-summary-group simple-row">
        <template v-if="isSummaryReady(transactionSummary)">
          <div class="summary-item secondary-summary" style="display: flex; flex-direction: row">
            <div class="combination">
              <div class="summary-title">Payment Link</div>
              <div class="currency-text-tr">{{ transactionSummary.transactionCountForReasonType.paymentLink }}</div>
            </div>

            <div class="combination">
              <div class="summary-title">App</div>
              <div class="currency-text-tr">{{ transactionSummary.transactionCountForReasonType.app }}</div>
            </div>

            <div class="combination">
              <div class="summary-title">USSD</div>
              <div class="currency-text-tr">{{ transactionSummary.transactionCountForReasonType.ussd }}</div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="summary-item secondary-summary" style="justify-content: center;">
            <span class="summary-amount-tr">Channel Transactions</span>
          </div>
        </template>
      </div>
    </div>
    <div v-if="transactionSummary.accountBalance" class="last-updated-time">
      Last Updated : {{ this.currentTime }}
    </div>

    <div v-if="disclaimerMessage" class="disclaimer-message-container">
      {{disclaimerMessage}}
    </div>

  </div>
</template>

<script>
export default {
  name: "TransactionSummary",
  data: () => ({
    monthNameList: [],
    selectedMonth: '',
    time: null,
    channelList: [],
    transactionSummary: {
      accountBalance: '',
      paymentReceived: '',
      cashOut: '',
      transactionCount: '',
      transactionCountForReasonType: ''
    },
    date: '',
    currentTime: '',
    disclaimerMessage: null
  }),
  created() {
    this.$store.commit('setTransactionHistory', "")
  },
  mounted() {
    this.monthNameList = this.getLastMonths(3);
    this.getInitialSummary();
  },
  methods: {
    isSummaryReady(transactionSummary) {
      return transactionSummary.accountBalance !== '';
    },
    selectedChannelUpdated() {
      this.fetchTransactionSummary("manual");
    },
    selectedMonthUpdated(selectedMonth) {
      if (this.$store.getters.getTransactionHistory === '') {
        this.fetchTransactionSummary("month-filter-change");
      } else {
        this.setLatestMonthTransaction(selectedMonth);
      }
    },
    toCommas(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getInitialSummary() {
      this.fetchTransactionSummary('refreshButton');
    },
    //For extracting last three months
    getLastMonths(n) {
      return Array.apply(null, new Array(n)).map((v, k) => {
        const date = new Date();
        date.setDate(1);
        if (k !== 0) {
          date.setMonth(date.getMonth() - k);
        }
        return date.toLocaleString('default', {month: 'long'})
      })
    },
    fetchTransactionSummary(source) {
      let payload = {};
      this.$feedback.showLoading();
      this.$cppClient.post('transaction-summary', payload)
          .then(({data}) => {
            console.debug("transaction-summary", payload, data)
            this.$feedback.hideLoading();
            this.$store.commit('setTransactionHistory', data);
            this.transactionSummary = this.$store.getters.getCurrMonth;
            this.disclaimerMessage = this.$store.getters.getDisclaimerMessage;
            this.currentTime = this.$store.getters.getCurrentTime;
            this.setLatestMonthTransaction(this.selectedMonth ? this.selectedMonth : source);
          })
          .catch(({response}) => {
            this.isLoading = false;
            this.$feedback.hideLoading();
            this.$feedback.showSubtleErrorToast("Failed to load data. Please Refresh!");
            this.logger('Exception from transaction summary', response);
          })
    },
    setLatestMonthTransaction(val) {
      if (val === this.monthNameList[0]) {
        this.transactionSummary = this.$store.getters.getCurrMonth;
      } else if (val === this.monthNameList[1]) {
        this.transactionSummary = this.$store.getters.getPrevMonth;
      } else if (val === this.monthNameList[2]) {
        this.transactionSummary = this.$store.getters.getPrev2Month;
      } else {
        this.selectedMonth = this.monthNameList[0];
        this.transactionSummary = this.$store.getters.getCurrMonth;
      }
    },
    monthNameClicked(monthName) {
      this.selectedMonth = monthName;
      this.$gtag.event("month selected - transaction summary", {
        'event_category': "Transaction Report Page"
      });
      this.selectedMonthUpdated(monthName)
    }
  }
}
</script>

<style>

.new-summary-group {
  flex: 1;
}

.last-updated-time {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #707070;
  margin-top: 12px;
}

.currency-text-tr {
  font-size: 23px;
  color: #e2136e;
  margin-right: 8px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  margin-top: -5px;
  letter-spacing: normal;
  text-align: center;
  font-family: 'Hind Siliguri', Roboto !important;
}

.secondary-summary {
  min-width: 100px;
  margin-top: 0px;
  flex-direction: column;
}

.summary-item {
  flex-grow: 1;
  width: 50%;
  display: flex;
  height: 80px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  margin: 0 20px 0 0;
  padding: 11px 20px 9px 20px;
}

.summary-title {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #777777;
}

.summary-amount-tr {
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #777777;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px;
}

@media only screen and (max-width: 1264px) {
  .summary-amount-tr {
    font-size: 14px;
  }

  .summary-title {
    font-size: 10px;
  }

  .summary-amount-tr {
    font-size: 17px;
  }

  .currency-text-tr {
    font-size: 17px;
    margin-top: -4px;
  }
}

@media only screen and (max-width: 900px) {
  .new-summary-group {
    margin-top: 20px;
  }

  .new-summary-group .summary-item:last-child {
    margin-right: 0px !important;
  }
}

.new-summary-group:last-child .summary-item:last-child {
  margin-right: 0px !important;
}

.summary-month-selector .v-select__selection {
  color: whitesmoke !important;
  margin-left: 8px !important;
  font-size: 10px;
}

.summary-channel-selector .v-select__selection {
  margin-left: 8px !important;
  font-size: 10px;
}

.summary-month-selector .v-icon {
  color: whitesmoke !important;
}

@media only screen and (max-width: 600px) {
  .summary-title {
    font-size: 10px;
  }
}

.buttoned-month-selector-container {
  display: flex;
  margin-top: -100px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.buttoned-month-selector-label {
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #9a9a9a;
  margin-right: 12px;
}

.buttoned-month-selector {
  margin: 30px 8px;
  padding: 5px 27px 6px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
  background-color: #95989a;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: white;
}

.buttoned-month-selector:last-child {
  margin-right: 0;
}

.buttoned-month-selector-active {
  background-color: #e2136e;
}

.combination {
  flex: 1;
  border-right: 1px solid #ededed;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.combination:last-child {
  border-right: none;
}

.combination .currency-text-tr {
  padding-top: 5px;
}

@media only screen and (max-width: 900px) {
  .buttoned-month-selector-container {
    margin-top: 0px;
    flex-direction: column;
    align-items: start;
  }

  .buttoned-month-selector {
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
    flex: 1;
    font-size: 10px;
    margin: 8px 4px 0px;
  }

  .buttoned-month-selector:first-child {
    margin-left: 0px;
  }

  .buttoned-month-selector-label {
    font-size: 15px;
  }

  .buttoned-month-selector-inner-container {
    width: 100%;
    flex: 1;
  }
}

.smaller{
  font-size: 20px !important;
}

.disclaimer-message-container{
  display: flex;
  border: 2px solid #e2136e;
  border-radius: 6px;
  background-color: #fcfcfc;
  padding: 10px;
  margin-top: 10px;
  justify-content: center;
}

</style>
