<template>
  <div>

    <!--  Mobile UI - start  -->
    <div class="transaction-table-mobile" :hidden="!isMobile"
         style="">

      <div class="no-results-message" v-if="!loading && transactions.length === 0">
        No results found for your query
      </div>

      <div v-for="transaction in transactions" :key="makeUniqueRowKey(transaction)">
        <div class="trx-mob-container">

          <!--  Inactive area - start  -->
          <div class="trx-mob-inactive-div" v-if="!isActive(transaction, activeIndex)">
            <v-btn
                class="trx-more-button"
                icon
                @click="moreButtonClicked(transaction)"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
            <div class="customerPhoneNumber">{{ stripCountryCode(transaction.customerPhoneNumber) || "Unknown" }}</div>
            <div class="simple-row" style="margin-top: 16px; ">
              <div class="amount">{{ transaction.amount | twoDecimalPlace }}</div>
              <div class="simple-filler"></div>
              <div class="trxId">{{ transaction.trxId }}</div>
            </div>
            <div class="simple-row" style="margin-top: 12px;">
              <div class="transactionDateTime" style="margin-right: 4px;">{{ transaction.transactionDateTime }}</div>
              <div class="simple-filler"></div>
              <div class="trxChannel">{{ transaction.trxChannel || "Unknown Channel" }}</div>
            </div>
          </div>
          <!--  Inactive area - end  -->

          <!--  Active area - start  -->
          <div class="trx-mob-active-div" v-if="isActive(transaction, activeIndex)">
            <v-btn
                class="trx-close-button"
                icon
                @click="closeButtonClicked()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <div class="customerInfoLabel">Customer Info:</div>
            <div class="customerInfoContent" style="margin-top: 6px;">{{ transaction.customerInformation }}</div>
            <div class="invoiceNumber" style="margin-top: 6px;">
              <span style="color: #707070;">Invoice:</span> {{ transaction.invoiceNumber }}
            </div>
          </div>
          <!--  Active area - end  -->

        </div>
      </div>
    </div>
    <!--  Mobile UI - end  -->

    <v-data-table
        :hidden="isMobile"
        :loading="loading"
        :headers="headers"
        :items="transactions"
        :hide-default-footer="true"
        class="elevation-1 transaction-list"
        loading-text="Loading... Please wait"
        no-data-text="No results found for your query"
    >
      <template v-slot:item.transactionDateTime="{item}">
        {{ item.transactionDateTime }}
      </template>
      <template v-slot:item.customerPhoneNumber="{item}">
        {{ item.customerPhoneNumber | walletNumber }}
      </template>
      <template v-slot:item.amount="{item}">
        {{ item.amount | twoDecimalPlace }}
      </template>
      <template v-slot:item.customerInformation="{item}">
          <span v-for="line in formatInfo(item.customerInformation)" :key="line">
            {{ line }}<br/>
          </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>

export default {
  name: "TransactionList",
  props: {
    transactions: Array,
    loading: Boolean,
  },
  methods: {
    formatInfo(info) {
      return info.replace(/,\s/g, ',').split(',');
    },
    makeUniqueRowKey(transaction) {
      return transaction.trxId + '-' + transaction.transactionDateTime;
    },
    moreButtonClicked(transaction) {
      this.activeIndex = this.makeUniqueRowKey(transaction);
    },
    closeButtonClicked() {
      this.activeIndex = null;
    },
    isActive(transaction, activeIndex) {
      return this.makeUniqueRowKey(transaction) === activeIndex;
    },
    stripCountryCode(wallet) {
      if (!wallet) return "";
      return wallet.replace('+880', '0');
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  data() {
    return {
      activeIndex: null,
      headers: [
        {text: 'Date & Time', align: 'start', value: 'transactionDateTime', class: 'table-header-text'},
        {text: 'Customer Wallet', class: 'table-header-text', value: 'customerPhoneNumber'},
        {text: 'Amount', class: 'table-header-text', value: 'amount'},
        {text: 'Transaction ID', class: 'table-header-text', value: 'trxId'},
        {text: 'Invoice No', class: 'table-header-text', value: 'invoiceNumber'},
        {text: 'Payment Info', class: 'table-header-text', value: 'customerInformation'},
      ],
    }
  },
}
</script>

<style lang="scss" scoped>

.transaction-table-mobile {
  background: white;
  padding-top: 2px;
  padding-bottom: 2px;

  .no-results-message {
    text-align: center;
    margin: 8px;
    color: #777777;
  }

  .trx-mob-container {
    background: #f4f7f9;
    margin: 8px 8px;
    padding: 12px;

    .trx-mob-inactive-div {
      min-height: 88px;

      .trx-more-button {
        right: -8px;
        top: -8px;
        float: right;
        color: #6e6e6e;
      }
    }

    .trx-mob-active-div {
      min-height: 88px;

      .trx-close-button {
        right: -8px;
        top: -8px;
        float: right;
        color: #6e6e6e;
      }
    }
  }

  .customerPhoneNumber {
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #6e6e6e;
  }

  .amount {
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #6e6e6e;
  }

  .trxId {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #777777
  }

  .transactionDateTime {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #95989a;
  }

  .trxChannel {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #95989a;
  }

  .customerInfoLabel {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #6e6e6e;
  }

  .customerInfoContent {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #95989a;
  }

  .invoiceNumber {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    text-align: left;
    color: #95989a;
  }
}

</style>

