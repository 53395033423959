<template>
  <div class="container mt-10">
    <div class="transaction-header">
      <div class="page-heading page-heading-tr simple-no-text-wrap" style="padding-top: 2px; min-height: 28px;">
        Transaction Report
      </div>
    </div>

    <transaction-summary></transaction-summary>

    <div class="simple-row--break-on-1080p transaction-header">
      <div class="simple-filler"></div>
      <div class="simple-row--break-on-mobile right-hand-container">
        <div class="simple-row date-picker-and-download-container">
          <v-select
              :items="listOfAvailableMonthlyReports"
              label="Report Period"
              item-text="label"
              v-model="selectedMonthlyReportPeriod"
              @change="selectedMonthlyReportPeriodChanged()"
              class="simple-stretch-on-mobile std-form-item report-period">
          </v-select>
        </div>
        <div class="simple-row date-picker-and-download-container">
          <date-picker v-model="dateRange" type="date" range placeholder="Select date range"
                       class="date-picker simple-stretch-on-mobile"
                       :disabled="selectedMonthlyReportPeriod!=='custom-date-range'"
                       :disabled-date="applyDateRangeFilter">
          </date-picker>
        </div>
        <div class="simple-row format-picker-and-download-button-container">
          <v-select
              :items="reportFormatList"
              item-text="label"
              v-model="selectedReportFormat"
              :disabled="selectedMonthlyReportPeriod!=='current-month' && selectedMonthlyReportPeriod!=='custom-date-range'"
              class="simple-stretch-on-mobile std-form-item report-format">
          </v-select>

          <a href="" id="download-report" :download="fileName" style="display: none"></a>
          <v-btn
              class="download-button"
              @click="downloadReport"
              :disabled="selectedMonthlyReportPeriod === 'custom-date-range' && dateRange[0] == null && dateRange[1] == null"
              color="primary">
            Request Report
          </v-btn>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 40px;">
      <v-btn-toggle
          v-model="toggleTable"
          mandatory
          color="white"
          dense
          active-class="active-button"
      >
        <v-btn
            style="font-size: 15px"
            value="allTrx"
            @click="allTrxTab"
        >All Transaction
        </v-btn>
        <v-btn
            style="font-size: 15px"
            value="paymentTrx"
            @click="paymentTrxTab"
        >Payment Transaction
        </v-btn>
      </v-btn-toggle>
    </div>
    <div v-if="toggleTable ==='allTrx'">
      <data-refresher v-if="isAutoRefreshEnabled"
                      @refresh-request="dataRefreshRequested"
                      ref="dataRefresher">
      </data-refresher>

      <v-btn
          class="refresh-button"
          v-if="!isAutoRefreshEnabled"
          @click="dataRefreshRequested"
          rounded color="primary"
          style=" margin-top: 8px; margin-bottom: 16px;"
          outlined
      >
        <v-icon small left>mdi-reload</v-icon>
        Refresh
      </v-btn>

      <transaction-gql-list :transactions="transactions"
                            :loading="isLoading"
      ></transaction-gql-list>

      <pagination :is-disabled="isLoading"
                  :item-count="transactions.length"
                  :tokens="tokensForAllTrx"
                  @next="getNextGqlTransactionList"
                  @previous="getPreviousGqlTransactionList"
      ></pagination>

    </div>
    <div v-if="toggleTable ==='paymentTrx'">
      <div v-if="paymentTrxMessage" v-html="paymentTrxMessage" style="margin-bottom: 10px;">
      </div>

      <v-btn
          class="refresh-button"
          @click="refreshPaymentTrxList"
          rounded color="primary"
          style=" margin-top: 8px; margin-bottom: 16px;"
          outlined
      >
        <v-icon small left>mdi-reload</v-icon>
        Refresh
      </v-btn>
      <transaction-list :transactions="paymentTransactions"
                        :loading="isLoading">
      </transaction-list>

      <pagination :is-disabled="isLoading"
                  :item-count="paymentTransactions.length"
                  :tokens="tokens"
                  @next="getNextPaymentTrxList"
                  @previous="getPrevPaymentTrxList"
      ></pagination>
    </div>
  </div>
</template>

<script>

import TransactionGqlList from "@/components/transactionReport/TransactionGqlList";
import moment from "moment";
import {getReportFileName} from "cpp-js-util/CppUtil";
import DataRefresher from "@/components/common/DataRefresher";
import TransactionSummary from "@/components/transactionReport/TransactionSummary";
import TransactionList from './TransactionList.vue';
import Pagination from "cpp-js-util/components/common/Pagination";


export default {
  name: "TransactionReport",
  components: {
    DataRefresher,
    TransactionGqlList,
    TransactionSummary,
    TransactionList,
    Pagination,
  },
  data() {
    return {
      transactions: [],
      paymentTransactions: [],
      tokensForAllTrx: [],
      dateRange: [],
      isLoading: false,
      listOfAvailableMonthlyReports: [],
      selectedMonthlyReportPeriod: null,
      toggleTable: "allTrx",
      tokens: [],
      reportFormatList: [
        {value: "pdf", label: "PDF"},
        {value: "csv", label: "CSV"},
        {value: "xlsx", label: "XLSX"},
      ],
      selectedReportFormat: "pdf",
      isAutoRefreshEnabled: true,
      paymentTrxMessage: null
    };
  },
  mounted() {
    this.$gtag.event("landed on transaction report page", {
      'event_category': "Transaction Report Page"
    });
  },
  created() {
    this.getGqlTransactionList();
    this.populateReportPeriods();
    this.dateRange = [
      new Date(new Date().setDate((new Date()).getDate() - 29)),
      new Date(),
    ];
  },
  computed: {
    fileName() {
      return getReportFileName();
    }
  },
  methods: {
    async getGqlTransactionList() {
      this.isLoading = true;
      this.$cppClient.post('transaction/entire/list', {})
          .then(({data}) => {
            this.isLoading = false;
            this.transactions = data.trxList;
            this.tokensForAllTrx = ['', data.nextToken];
            this.$refs.dataRefresher.notifyDataRefresh(true);
          })
          .catch(err => {
            this.isLoading = false;
            console.debug(err.response);
            this.$refs.dataRefresher.notifyDataRefresh(false);
          });
    },

    async getPaymentTrxText() {
      let payload = {};
      this.$cppClient.post('transaction/payment/message/get', payload)
        .then(({data}) => {
          this.paymentTrxMessage = data;
        })
        .catch(err => {
          this.isLoading = false;
          console.debug(err.response);
        });

    },
    async getPaymentTransactionList() {
      this.isLoading = true;

      let payload = {}
      payload.fromDate = new Date(new Date().setDate((new Date()).getDate() - 30));
      payload.fromDate = moment(payload.fromDate).format('YYYY-MM-DD 00:00:00')

      this.$cppClient.post('transaction-list', payload)
          .then(({data}) => {
            this.isLoading = false;
            this.paymentTransactions = data.result;
            this.tokens = ['', data.nextToken];
          })
          .catch(err => {
            this.isLoading = false;
            console.debug(err.response);

          });
    },
    stripCountryCode(shortCode) {
      if (shortCode.slice(0, 1) === '+') {
        return shortCode = shortCode.substring(3);
      } else return shortCode;
    },
    selectedMonthlyReportPeriodChanged() {
      if (this.selectedMonthlyReportPeriod === "current-month") {
        this.dateRange = [
          new Date(new Date().setDate((new Date()).getDate() - 29)),
          new Date(),
        ];
      } else {
        this.dateRange = [];
      }
      console.debug("Selected item changed", this.selectedMonthlyReportPeriod);
      this.selectedReportFormat = "pdf";
    },
    async populateReportPeriods() {
      this.listOfAvailableMonthlyReports = [
        {value: "custom-date-range", label: "Fixed Date Range"},
        {value: "current-month", label: "Last 30 days"},
      ];
      this.selectedMonthlyReportPeriod = "custom-date-range";

      try {
        this.$feedback.showLoading();
        let periodList = (await this.$cppClient.post('transaction/list-available-monthly-reports', {})).data;
        periodList.forEach(period => {
          period.value = period.statementId;
          period.label = `${this.toProperCase(period.monthlyStatementMonth)} ${period.monthlyStatementYear}`;
          this.listOfAvailableMonthlyReports.push(period);
        });
      } catch (ex) {
        console.error(ex);
        this.$feedback.showSubtleErrorToast("Unable to load existing reports. Please refresh.");
      } finally {
        this.$feedback.hideLoading();
      }
    },
    applyDateRangeFilter(date) {
      const today = new Date();
      const priorDate = new Date(new Date().setDate((new Date()).getDate() - 30));
      return date.getTime() < priorDate.getTime() || date.getTime() > today.getTime();
    },
    getDateRangeValue() {
      let [fromDate, toDate] = this.dateRange;
      let dateRange = {};

      if (fromDate !== undefined && fromDate !== null && toDate !== undefined && toDate !== null) {
        fromDate = moment(fromDate).format('YYYY-MM-DD hh:mm:ss');
        toDate = moment(toDate).format('YYYY-MM-DD hh:mm:ss');

        dateRange = {fromDate: fromDate, toDate: toDate};
      }
      return dateRange;
    },
    async downloadReport() {
      this.$gtag.event("report download request button clicked - transaction report page", {
        'event_category': "Transaction Report Page"
      });

      let requestBody = {};
      if (this.selectedMonthlyReportPeriod === "current-month" || this.selectedMonthlyReportPeriod === "custom-date-range") {
        requestBody.onDemandPeriodType = "CURRENT_MONTH";

        let range = this.getDateRangeValue();
        range.fromDate = range.fromDate.split(' ').shift();
        range.toDate = range.toDate.split(' ').shift();

        Object.assign(requestBody, range);
        requestBody.statementId = null;
      } else {
        requestBody.onDemandPeriodType = "ANY_PREVIOUS_MONTH";
        Object.assign(requestBody, {fromDate: null, toDate: null});
        requestBody.statementId = this.selectedMonthlyReportPeriod;
      }

      requestBody.reportFormat = this.selectedReportFormat;

      try {
        this.$feedback.showLoading();
        let res = (await this.$cppClient.post('transaction/request-transaction-report-by-mail', requestBody)).data;
        if (res.result !== "success") throw new Error("Unexpected error occurred");

        let accountInfo = null;
        try {
          accountInfo = (await this.$cppClient.post('profile/info', {})).data;
        } catch (ex) {
          console.error(ex);
          this.$feedback.showFailed();
        }

        let email = (accountInfo ? (' ' + accountInfo.email) : '');
        let period;
        if (this.selectedMonthlyReportPeriod === "current-month" || this.selectedMonthlyReportPeriod === "custom-date-range") {
          period = `for the period of ${requestBody.fromDate} to ${requestBody.toDate}`;
        } else {
          let periodObject = this.listOfAvailableMonthlyReports.find(report => report.value == this.selectedMonthlyReportPeriod);
          period = `for the month of ${periodObject.label}`;
        }
        let message = `Your report ${period} will be sent to your email address${email}. Please note that delivering reports may take longer depending to report size and system resource availability.`;
        this.$feedback.showSuccessMessage(message);
      } catch (ex) {
        console.error(ex);
        this.$feedback.showFailed(ex.response);
      } finally {
        this.$feedback.hideLoading();
      }
    },
    async dataRefreshRequested() {
      await this.getGqlTransactionList()
    },
    toProperCase(string) {
      string = String(string);
      if (string.length === 0) return string;
      return string.charAt(0).toUpperCase() + string.substring(1);
    },
    getNextGqlTransactionList(requestBody) {
      this.isLoading = true;
      this.$cppClient.post('transaction/entire/list', requestBody)
          .then(({data}) => {
            this.isLoading = false;
            this.transactions = data.trxList;
            this.tokensForAllTrx.push(data.nextToken);
            this.isAutoRefreshEnabled = false;
          })
          .catch(err => {
            this.isLoading = false;
            console.debug(err.response);
          });
    },
    getPreviousGqlTransactionList(requestBody) {
      this.isLoading = true;
      this.$cppClient.post('transaction/entire/list', requestBody)
          .then(({data}) => {
            this.isLoading = false;
            this.transactions = data.trxList;
            this.tokensForAllTrx.pop();
            if (this.tokensForAllTrx.length < 3) {
              this.isAutoRefreshEnabled = true;
              this.$nextTick(() => {
                this.$refs.dataRefresher.notifyDataRefresh(true);
              })
            }
          })
          .catch(err => {
            console.log("In catch block");
            this.isLoading = false;
            console.debug(err.response);
            this.$refs.dataRefresher.notifyDataRefresh(false);
          });
    },
    async getNextPaymentTrxList(requestBody) {
      this.isLoading = true;

      requestBody.fromDate = new Date(new Date().setDate((new Date()).getDate() - 30));
      requestBody.fromDate = moment(requestBody.fromDate).format('YYYY-MM-DD 00:00:00');

      this.$cppClient.post('transaction-list', requestBody)
          .then(({data}) => {
            this.isLoading = false;
            this.paymentTransactions = data.result;
            this.tokens.push(data.nextToken);
          })
          .catch(err => {
            this.isLoading = false;
            console.debug(err.response);
          });
    },
    async getPrevPaymentTrxList(requestBody) {
      this.isLoading = true;

      requestBody.fromDate = new Date(new Date().setDate((new Date()).getDate() - 30));
      requestBody.fromDate = moment(requestBody.fromDate).format('YYYY-MM-DD 00:00:00');

      this.$cppClient.post('transaction-list', requestBody)
          .then(({data}) => {
            this.isLoading = false;
            this.paymentTransactions = data.result;
            this.tokens.pop();
          })
          .catch(err => {
            this.isLoading = false;
            console.debug(err.response);
          });
    },
    refreshPaymentTrxList() {
      this.getPaymentTrxText();
      this.getPaymentTransactionList();
    },
    paymentTrxTab() {
      this.getPaymentTrxText();
      this.getPaymentTransactionList();
      this.tokensForAllTrx = [];
    },
    allTrxTab() {
      this.getGqlTransactionList();
      this.tokens = [];
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .v-input__slot::before {
  border-style: none !important;
}

.container {
  max-width: 1185px;
  padding-top: 80px;
}

.search-filter-name {
  width: 160px;
  margin-right: 8px;
  flex-grow: 0;
}

.search-filter-name .v-select__selection {
  margin-left: 8px !important;
  font-size: 13px;
}

.report-period {
  width: 160px;
  margin-right: 0px;
  flex-grow: 0;
  margin-top: 9px;
}

.report-format {
  width: 80px;
  margin-left: 8px;
  margin-right: 0px;
  flex-grow: 0;
  margin-top: 9px;
}

.report-period .v-select__selection {
  margin-left: 8px !important;
  font-size: 13px;
}

.report-format .v-select__selection {
  margin-left: 8px !important;
  font-size: 13px;
}

.search-filter-value {
  width: 240px;
  margin-right: 8px;
  flex-grow: 0;
}

.search-filter-value .v-text-field__slot {
  margin-left: 8px !important;
  font-size: 12px;
}

.search-filter-value .v-label {
  font-size: 12px;
}

.filter-area {
  margin-top: 12px;
  align-items: start;
}

.date-picker {
  width: 240px;
  margin-left: 8px;
  font-size: 12px !important;
  padding-top: 6px;
  padding-bottom: 6px;

}

.date-picker * {
  font-size: 12px !important;
}

.not-current-month {
  visibility: hidden;
}

.transaction-header {
  margin-top: 0px;
  margin-bottom: 27px;
}

.page-heading-tr {
  margin-right: 8px;
  align-self: center
}

.date-range-container {
  text-align: right;
  margin-top: -30px;
  margin-bottom: 20px;
}

.download-button {
  margin-left: 6px;
}

.download-button * {
  font-weight: normal;
  font-size: 13px !important;
  letter-spacing: normal !important;
}

.search-icon-btn {
  margin-top: -6px;;
}

.search-icon-btn * {
  font-size: 16px !important;
}

.active-button {
  color: #ffffff;
  background-color: #e2136e !important;
}

@media screen and (max-width: 900px) {
  .transaction-header {
    margin-top: 20px !important;
    margin-bottom: 10px !important;
  }

  .date-range-container {
    margin-top: -10px;
  }

  .filter-area {
    width: 100%;
  }

  .download-button {
    margin-left: 2px;
  }
  .container{
    padding-top: 12px;
  }
}

@media only screen and (max-width: 1080px) {
  .date-picker {
    margin-left: 0px;
  }

  .report-period {
    margin-right: 8px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 900px) {
  .page-heading-tr {
    display: block !important;
    line-height: 1.4 !important;
  }
}

.right-hand-container {
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .left-hand-container {
    flex-direction: column;
    align-items: normal;

    .search-filter-value {
      margin-top: -10px;
    }

    .search-icon-btn {
      margin-top: -20px;
    }

    .search-filter-name .v-select__selection {
      font-size: 11px;
    }

    .search-filter-value .v-text-field__slot {
      font-size: 11px;
    }

    .search-filter-value .v-label {
      font-size: 11px;
    }

  }

  .right-hand-container {
    margin-top: 12px;

    .date-picker {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: -10px;
    }

    .report-period {
      margin-right: 0px;
      //margin-top: 0px;
    }

    .report-format {
      margin-left: 0px;
      margin-right: 8px;
    }

    .date-picker-and-download-container {
      // flex-direction: row-reverse;
    }

    .format-picker-and-download-button-container {
      margin-top: -10px;
      margin-bottom: 10px;
    }

  }

  .download-button * {
    font-size: 12px !important;;
  }

  .date-picker * {
    font-size: 12px !important;;
  }

  .search-container {
    margin-top: -6px;
    margin-bottom: -12px;
  }
}

@import 'calendar.css';

</style>
